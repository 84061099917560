import { computed } from 'vue'
import { useAdminStore } from '@voix/store/adminStore'
import { shade } from '@voix/composables/useColorUtils'

export function useVoixTheme() {
  const adminStore = useAdminStore()

  const themeColor = computed(() => {
    return adminStore.themeColor
  })
  const themeColorLight = computed(() => {
    return shade(adminStore.themeColor, 0.80)
  })
  const themeColorLightest = computed(() => {
    return shade(adminStore.themeColor, 0.90)
  })
  const themeColorDark = computed(() => {
    return shade(adminStore.themeColor, -0.20)
  })
  const themeColorDarkest = computed(() => {
    return shade(adminStore.themeColor, -0.90)
  })

  return { themeColor, themeColorLight, themeColorLightest, themeColorDark, themeColorDarkest }
}
